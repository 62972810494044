import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ large = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const logo = (
    <Box component="img" src="/logo/logo.png" sx={{ width: '90%', cursor: 'pointer', ...sx }} />
  );

  const logoSquare = <Box component="img" src="/logo/logo.png" sx={{ width: 164.6, height: 60 }} />;

  if (large) {
    return <>{logo}</>;
  }

  return logoSquare;
});

Logo.propTypes = {
  small: PropTypes.bool,
  large: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
